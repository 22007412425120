<template>
  <div class="m-topic-card">
    <div class="img" :style="{
      'background-image': `url('${model.ex_pic}')`
    }">
      <div class="tag">{{model.Theme_Type}}</div>
    </div>
    <div class="foot">
      <p class="name">{{model.Name}}</p>
      <button class="detail-btn" @click="onDetail(model)">
        <span class="icon el-icon-right"></span>
      </button>
    </div>
  </div>
</template>

<script>
import dThemeApi from '@/api/dTheme'
export default {
  name: 'mTopicCard',
  props: {
    model: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      sampleIds: []
    }
  },
  methods: {
    getData() {
      this.loading = true
      dThemeApi.getDetailPage({
        page: 1,
        rows: 999999,
        value: this.model.ID
      }).then(res => {
        let sampleIds = res.rows.map(item => item.Sample_ID)
        this.sampleIds = sampleIds
      })
    },
    onDetail(model) {
      let wheres = []
      wheres.push({name: 'Sample_ID', value: this.sampleIds.join(','), displayType: 'selectList'})
      let url = `/search/result?wheres=${JSON.stringify(wheres)}`
      this.$router.push(url)
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="less" scoped>
.m-topic-card {
  margin-bottom: 30px;
  min-width: 100px;
  max-width: 400px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  .img {
    position: relative;
    height: 240px;
    background-color: #C0C4CC;
    background-position: center;
    background-size: cover;
    .tag {
      position: absolute;
      top: 8px;
      left: 8px;
      padding: 10px 14px;
      line-height: 1em;
      font-size: 14px;
      color: #fff;
      background-color: rgba(245,108,108,.95);
    }
  }
  .foot {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid #eee;
    .name {
      font-size: 16px;
      // font-weight: bold;
    }
    .detail-btn {
      position: relative;
      // left: -30px;
      padding: 0 24px;
      // border: 2px solid #eee;
      border: none;
      background-color: #409eff;
      color: #fff;
      line-height: 38px;
      font-size: 18px;
      // opacity: 0;
      transition: all .2s ease;
      cursor: pointer;
      .icon {
        display: inline-block;
        position: relative;
        left: 0;
        transition: all .2s ease;
      }
      &:hover {
        opacity: .9;
        // background-color: #eee;
        .icon {
          left: -8px;
        }
      }
      &:active {
        .icon {
          left: 14px;
        }
      }
    }
  }
  &:hover {
    // .detail-btn {
    //   left: 0;
    //   opacity: 1;
    // }
  }
}
</style>

<template>
  <div class="m-pg-sub">
    <PageCover code="17" layer="rgba(0,0,0,.3)">
      <!-- <h2 class="m-page-title">{{$t('page.journal.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.journal.name')}}</el-breadcrumb-item>
      </el-breadcrumb> -->
    </PageCover>
    <div class="m-pg-sub_main m-w1200">
      <el-row :gutter="30">
        <el-col :span="6" v-for="item in data" :key="item.ID">
          <topic-card :model="item"></topic-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import TopicCard from '@/components/topic-card'
import dThemeApi from '@/api/dTheme'
import setting from '@/setting'
export default {
  name: 'Search_dTheme',
  components: {
    TopicCard
  },
  data() {
    return {
      loading: false,
      data: [],
      page: {
        index: 1,
        size: 10,
        total: 0
      }
    }
  },
  methods: {
    getData() {
      let { type } = this.$route.query
      this.loading = true
      dThemeApi.getPageData({
        page: this.page.index,
        rows: this.page.size,
        wheres: `[{"name":"Theme_Type","value":"${type ? type : '期刊'}","displayType":"select"}]`
      }).then(res => {
        this.loading = false
        res.rows.forEach(item => {
          item.ex_pic = item.Pic ? setting.baseUrl.img + '/' + item.Pic : ''
        })
        this.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    padding: 30px 0;
  }
</style>

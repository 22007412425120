/**专题 */
import request from '@/utils/request'

export function getPageData(data) {
  return request({
    url: '/D_Theme/getPageData',
    method: 'post',
    data
  })
}
export function getDetailPage(data) {
  return request({
    url: '/D_Theme/getDetailPage',
    method: 'post',
    data
  })
}
export default {
  getPageData,
  getDetailPage
}
